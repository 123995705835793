@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
$family-sans-serif: "Inter", "Helvetica", "Helvetica Neue", "Arial", sans-serif;
$text: #ffffff;
$black: #000000;
$red: #e62b1e;
$widescreen: 1200px;
$fullhd: 1200px;
$link: #e2e2e2;
// $link-hover: #ffffff;
$navbar-item-img-max-height: 6rem;
$footer-background-color: gray;
$footer-padding: 2rem 2rem 2rem;
$navbar-padding: 3rem 6rem 6rem;
$navbar-item-hover-color: #000000;
$timeline-line: #101010;
$button-background-color: #e62b1e;
$button-border-width: 0;
$button-color: #ffffff;
$button-focus-color: #ffffff;
$button-active-color: #ffffff;
$triggerStyle: #ffffff;
@import "~bulma/bulma.sass";
@import "~bulma-timeline";
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@700&display=swap");


html,
body {
  background-color: black;
}

.font-size-good {
  font-size: 14rem;
}
.navbar-logo {
  max-height: 80px;
}

.link-on-black:hover {
  color: #e62b1e;
}

.section {
  background-color: black;
}
.one {
  position: relative;
  min-height: 600px;
  width: 100vw;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: middle;
  position: absolute;
  width: 100%;
  height: 100%;
}
.one-logo {
  margin: 0 auto;
}
.particle {
  min-height: 100px;
  width: 100%;
}
.three {
  background-color: #e62b1e;
  min-height: 400px;
  display: flex;
  vertical-align: middle;
}

.TED {
  color: #e62b1e;
}

.bg-grey {
  background-color: #101010;
}

.section-title {
  color: #e62b1e;
  font-family: "Archivo", sans-serif;
  font-size: 5rem;
}

.video {
  position: relative;
  left: -1.5rem;
  top: -3rem;
}

.footer {
  background-color: #000000;
  border-top: 2px solid #e62b1e;
}

.menu {
  position: fixed;
  top: 0;
  background-color: #ffffff;
}
.link-on-white {
  color: rgba(0, 0, 0, 0.48);
}
.link-on-white:hover {
  color: #e62b1e;
}
.active {
  color: #e62b1e !important;
}
.mobile {
  background-color: #000000;
  .navbar-item {
    border-width: 0px !important;
  }
  .navbar-item:hover {
    background-color: #ffffff;
    a {
      color: #000000;
    }
  }
}
.bg-black {
  background-color: black;
}

.word-format {
  text-align: justify;
  text-justify :distribute;
}
.card-height {
  height: 32rem/* 384px */;
}
